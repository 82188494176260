import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Box } from "@xstyled/styled-components";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import getform from "../../../constants/getform";
import Container from "../../layout/Container/container";
import Headline from "../../typography/Headline/headline";

const ContactForm = ({ data }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });

    const [values, setValues] = useState({
        name: "",
        email: "",
        tour: "none",
        date: new Date(),
        message: "",
    });

    const handleDateChange = (date) => {
        setValues({ ...values, date });
    };

    const handleTextFieldChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        });
        if (ok) {
            form.reset();
        }
    };

    const submitForm = useCallback(
        async (form) => {
            if (!executeRecaptcha) {
                return;
            }

            const token = await executeRecaptcha("yourAction");

            setServerState({ submitting: true });
            axios({
                method: "post",
                url: `${getform.url}${getform.clientKey}`,
                data: {
                    ...values,
                    date: values.date.toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    }),
                    "g-recaptcha-response": token,
                },
            })
                .then(() => {
                    handleServerResponse(true, "Thanks!", form);
                })
                .catch((r) => {
                    handleServerResponse(false, r.response.data.error, form);
                });
        },
        [executeRecaptcha, values, setServerState, handleServerResponse]
    );

    const handleOnSubmit = useCallback(
        (e) => {
            e.preventDefault();
            submitForm(e.target);
        },
        [submitForm]
    );

    const handleReset = () => {
        setValues({
            name: "",
            email: "",
            tour: "none",
            date: new Date(),
            message: "",
        });
    };

    return (
        <Container>
            <Box my={5}>
                <Headline textAlign="center">Contact</Headline>
            </Box>
            <Box row justifyContent="center">
                <Box col={{ xs: 1, md: 1 / 2 }} mb={5}>
                    <form onSubmit={handleOnSubmit} onReset={handleReset}>
                        <Box mb={4}>
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                onChange={handleTextFieldChange}
                                value={values.name}
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box mb={4}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                onChange={handleTextFieldChange}
                                value={values.email}
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText="Your email will not be used for commercial purposes and will not be passed on to third party organisations"
                            />
                        </Box>
                        <Box mb={2}>
                            <FormControl variant="outlined" fullWidth>
                                <input
                                    type="hidden"
                                    name="_gotcha"
                                    style={{ display: "none !important" }}
                                />
                                <InputLabel required htmlFor="tour">
                                    Select your tour
                                </InputLabel>
                                <Select
                                    native
                                    required
                                    label="Select your tour&nbsp;&nbsp;"
                                    value={values.tour}
                                    onChange={handleTextFieldChange}
                                    inputProps={{
                                        name: "tour",
                                        id: "tour",
                                    }}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <option value="none">None</option>
                                    {data.allMarkdownRemark.nodes.map(
                                        ({ frontmatter }) => {
                                            const tourTitle = frontmatter.title;
                                            return (
                                                <option
                                                    key={tourTitle}
                                                    value={tourTitle}
                                                >
                                                    {tourTitle}
                                                </option>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mb={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="dialog"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date"
                                    name="date"
                                    label="Wished date"
                                    value={values.date}
                                    onChange={handleDateChange}
                                    fullWidth
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    required
                                    inputVariant="outlined"
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box mb={4}>
                            <TextField
                                id="message"
                                name="message"
                                label="Message"
                                onChange={handleTextFieldChange}
                                value={values.message}
                                multiline
                                required
                                fullWidth
                                rows={4}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder="Hello Virginia, ..."
                            />
                        </Box>
                        <Box mb={4} row justifyContent="center">
                            <Box mr={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={serverState.submitting}
                                >
                                    Send
                                </Button>
                            </Box>
                        </Box>
                        {serverState.status && (
                            <p
                                className={
                                    !serverState.status.ok ? "errorMsg" : ""
                                }
                            >
                                {serverState.status.msg}
                            </p>
                        )}
                    </form>
                </Box>
            </Box>
        </Container>
    );
};

ContactForm.propTypes = {
    data: PropTypes.object,
};

export default ContactForm;
