import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Layout from "../components/layout/Main/main";
import ContactForm from "../components/pages/contact/contact-form";
import SEO from "../components/seo";
import recaptcha from "../constants/recaptcha";

const Contact = ({ data }) => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={recaptcha.clientKey}
            language="en"
        >
            <Layout>
                <SEO
                    title="Contact me"
                    description="Book a tour or leave me a message"
                />
                <ContactForm data={data} />
            </Layout>
        </GoogleReCaptchaProvider>
    );
};

Contact.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query ContactPageQuery {
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "tour" } } }) {
            nodes {
                frontmatter {
                    title
                }
            }
        }
    }
`;

export default Contact;
